import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"
import MoodBarometer from "../../../../../content/kurse/haelfte-des-lebens/02-entstehungsgeschichte/mood-barometer"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const course = {
    courseId: "haelfte-des-lebens",
    chapterId: "02-entstehungsgeschichte",
  }
  const answer1 = getAnswer({
    ...course,
    taskId: "stimmungsbarometer-1",
  })
  const answer2 = getAnswer({
    ...course,
    taskId: "stimmungsbarometer-2",
  })
  const answer3 = getAnswer({
    ...course,
    taskId: "stimmungsbarometer-3",
  })

  const calculateAverageMoods = (moodDicts) => {
    const filteredDicts = moodDicts.filter((dict) => !!dict)
    const uniqueIds = [
      ...new Set(filteredDicts.map((moodDict) => Object.keys(moodDict)).flat()),
    ]
    const averageMoods = uniqueIds.reduce((acc, id) => {
      const summedMood = filteredDicts.reduce((acc, dict) => {
        const value = dict[id] ? dict[id] : 0
        return acc + value
      }, 0)
      const averageMood = Math.round(summedMood / filteredDicts.length)
      acc[id] = averageMood
      return acc
    }, {})
    return averageMoods
  }

  const averageMoods = calculateAverageMoods([answer1, answer2, answer3])

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/stimmungen-im-gedicht" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <Paragraph>Dein Stimmungsbarometer zu den Briefen:</Paragraph>
        <MoodBarometer disabled initialState={averageMoods} />
        <Paragraph>
          Immer wieder befindet sich Hölderlin in einer Art Lebenskrise, in der
          er nicht recht weiß, wohin mit sich und seinen Ambitionen. Jede neue
          Hauslehrerstelle lockt mit neuen Möglichkeiten, einem Ortswechsel, der
          Chance auf einen Neuanfang. Doch muss er sich dafür auch von den
          Freunden und Verwandten sowie der geliebten Heimat trennen. Aus den
          Briefen spricht somit eine quälende Unzufriedenheit auf der einen
          Seite, auf der anderen eine Sehnsucht nach Veränderung und Hoffnung
          auf Verbesserung seiner Lage. Doch werden seine Erwartungen oft genug
          enttäuscht.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
